<template>
  <div class="org">
    <a-row class="content-title" type="flex">
      <!--      <a-button-->
      <!--        type="primary"-->
      <!--        class="back-btn"-->
      <!--        style="margin-right: 10px"-->
      <!--        @click="$router.push({ name: 'reviews' })"-->
      <!--      >-->
      <!--        <i class="t-transition icons icon-chevron-left" />-->
      <!--        Назад-->
      <!--      </a-button>-->
      <h2 class="flex-1">{{ title[$i18n.locale] }}</h2>

      <a-row class="flex-no__wrap wanted-search-input-wrapper" type="flex">
        <a-button
          type="primary"
          @click="$router.push({ name: 'reviews-create' })"
        >
          <a-icon type="plus" />Добавить
        </a-button>
        <a-button
          :disabled="cardsIsEmpty"
          type="primary"
          style="margin-left: 10px"
          @click="removeItem(selectedPosts)"
        >
          <a-icon class="action-btns" type="delete" />
          Удалить
        </a-button>
      </a-row>
    </a-row>

    <a-table
      :columns="columns"
      :data-source="form"
      :loading="loading"
      :pagination="false"
      :row-key="(record) => record.id"
    >
      <template slot="id" slot-scope="item">
        <a-checkbox
          style="padding-right: 10px"
          :checked="selectedPosts.includes(+item.id)"
          :value="+item.id"
          @change="toggleItemId(item.id)"
        />
        <router-link
          :to="{ name: 'comments-update', params: { id: item.id } }"
          style="padding: 0"
          class="td-post-item"
          tag="span"
        >
          <b>{{ item.id }}</b>
        </router-link>
      </template>

      <template slot="author" slot-scope="item">
        <!--<spinner v-if="loading" />-->
        <div>
          <img
            style="width: 30px; height: 30px; object-fit: cover"
            alt="profile_img"
            src="@/assets/img/avatar.png"
          />
          <b>
            {{ item.author_name || "-" }} <span>{{ item.author_email }}</span>
          </b>
          <br />
          <span>{{ item.ip }}</span>
        </div>
      </template>

      <template slot="comment" slot-scope="item">
        <router-link
          :to="{ name: 'comments-update', params: { id: item.id } }"
          class="td-post-item"
          tag="span"
        >
          <div v-html="truncate(item.content, 150, '...')" />
        </router-link>
      </template>

      <template slot="status" slot-scope="item">
        <a-tag :color="item.is_active ? 'geekblue' : 'volcano'">
          {{ item.is_active ? $t("Published") : $t("NotPublished") }}
        </a-tag>
      </template>

      <template slot="created_at" slot-scope="item">
        {{ moment(item.created_at).format("LLL") }}
      </template>

      <template slot="updated_at" slot-scope="item">
        {{ moment(item.updated_at).format("LLL") }}
      </template>

      <template slot="operation" slot-scope="item">
        <a-button
          type="primary"
          class="edit-btn"
          @click="
            $router.push({ name: 'comments-update', params: { id: item.id } })
          "
        >
          <!--{{ $t("Edit") }}-->
          <a-icon class="action-btns" type="edit" />
        </a-button>
        <a-popconfirm
          cancel-text="Нет"
          ok-text="Да"
          title="Вы действительно хотите удалить?"
          @confirm="removeItem(item.id)"
        >
          <a-button type="danger">
            <a-icon class="action-btns" type="delete" />
            <!--{{ $t("Delete") }}-->
          </a-button>
        </a-popconfirm>
      </template>
    </a-table>

    <a-row class="mt-1" style="padding-bottom: 15px">
      <a-pagination
        :key="limit"
        :current="page"
        :default-page-size="limit"
        :total="count || 0"
        @change="setPage"
      />
    </a-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: [],
      loading: false,
      catTitle: {},
      title: {
        uz: "Izohlar",
        oz: "Изоҳлар",
        ru: "Комментарии",
        en: "Comments"
      },
      selectedPosts: [],
      cardsIsEmpty: true,
      columns: [
        {
          title: "ID",
          key: "id",
          width: 100,
          scopedSlots: { customRender: "id" }
        },
        {
          title: this.$t("Author"),
          key: "author",
          scopedSlots: { customRender: "author" }
        },
        {
          title: "Comment",
          key: "comment",
          scopedSlots: { customRender: "comment" },
          width: "40%"
        },
        {
          title: this.$t("Status"),
          key: "status",
          scopedSlots: { customRender: "status" }
          // width: "90%"
        },
        {
          title: this.$t("TableCreatedDate"),
          key: "created_datetime",
          width: 200,
          scopedSlots: { customRender: "created_at" }
        },
        {
          title: this.$t("TableLastUpdatedDate"),
          key: "updated_datetime",
          width: 200,
          scopedSlots: { customRender: "updated_at" }
        },
        {
          title: this.$t("TableAction"),
          key: "operation",
          width: 150,
          // align: "right",
          scopedSlots: { customRender: "operation" }
        }
      ],
      page: 1,
      limit: 10,
      offset: 0,
      count: 0
    }
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true
    }
  },
  methods: {
    truncate(text, length, clamp) {
      return this.$options.filters.truncate(text, length, clamp)
    },
    setPage(pageNumber) {
      this.$router.push({ query: { ...this.$route.query, page: pageNumber } })
    },
    toggleItemId(itemId) {
      const f = this.selectedPosts.findIndex((item) => +item === +itemId)
      if (f !== -1) {
        this.selectedPosts.splice(f, 1)
        this.cardsIsEmpty = false
      } else {
        this.selectedPosts.push(itemId)
        this.cardsIsEmpty = false
      }

      if (this.selectedPosts.length === 0) {
        this.cardsIsEmpty = true
      }
    },
    async fetchData() {
      this.loading = true
      this.offset = +this.$route.query.page || 1
      this.page = this.offset
      this.offset = (this.offset - 1) * this.limit
      try {
        const form = await this.$store.dispatch("comments/fetchCommentlist", {
          params: {
            limit: this.limit,
            offset: this.offset
          }
        })
        // console.log(form)
        this.form = form.results
        this.count = form.count
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    async removeItem(id) {
      if (Array.isArray(id)) {
        for (let i of id) {
          try {
            await this.$store.dispatch("comments/removeComment", i)
            this.$message.success("Успешно удалено")
          } catch (e) {
            this.$sentry.captureMessage(e)
          }
        }
      } else {
        try {
          await this.$store.dispatch("comments/removeComment", id)
          this.$message.success("Успешно удалено")
        } catch (e) {
          this.$sentry.captureMessage(e)
        }
      }
      await this.fetchData()
      this.selectedPosts = []
      this.cardsIsEmpty = true
      // console.log(this.selectedPosts)
    }
  }
}
</script>
<style scoped>
.wanted-search-input-wrapper {
  justify-content: flex-end;
}
</style>
